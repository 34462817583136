import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Text,
  Flex,
  Box,
  useToast,
} from '@chakra-ui/react';
import { updateDeliverableLinks, submitCollab } from '../../../services/firebaseService';
import deliverableNames from '../../../utils/deliverableNames';
import { Collab, DeliverableLink } from '../../../types';

interface SubmitCollabModalProps {
  isOpen: boolean;
  onClose: () => void;
  collab: Collab;
}

const SubmitCollabModal: React.FC<SubmitCollabModalProps> = ({ isOpen, onClose, collab }) => {
  const [deliverableLinks, setDeliverableLinks] = useState<{ [key: string]: DeliverableLink[] }>({});
  const toast = useToast();
  
  useEffect(() => {
    if (collab && collab.deliverableLinks) {
      setDeliverableLinks(collab.deliverableLinks);
    }
  }, [collab]);

  const handleInputChange = (key: string, index: number, value: string) => {
    setDeliverableLinks(prev => ({
      ...prev,
      [key]: prev[key].map((link, i) => i === index ? { ...link, userLink: value } : link)
    }));
  };

  const checkAllFieldsFilled = () => {
    const allLinks = Object.values(deliverableLinks).flat();
    const allFieldsFilled = allLinks.every(link => link.userLink.trim() !== '');
    return allFieldsFilled
  };

  const handleSave = async () => {
    try {
      await updateDeliverableLinks(collab.id, deliverableLinks);
      console.log('Saving current state:', deliverableLinks);
      onClose();
      toast({
        title: "Links saved.",
        description: "Your deliverable links have been saved successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error saving deliverable links:", error);
      toast({
        title: "Error saving links.",
        description: "Failed to save your deliverable links.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      await submitCollab(collab.id, deliverableLinks);
      console.log('Submitting collab:', deliverableLinks);
      onClose();
      toast({
        title: "Collab submitted.",
        description: "Your collab has been submitted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error submitting collab:", error);
      toast({
        title: "Error submitting collab.",
        description: "Failed to submit your collab.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const sortedDeliverables = Object.entries(deliverableLinks)
    .filter(([_, links]) => links.length > 0)
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Submit Collab</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {collab.status === 'pendingResubmission' && collab.rejectionReason && (
            <Box bg="red.100" p={3} borderRadius="md" mb={4}>
              <Text color="red.500" fontWeight="bold">
                Reason for rejection: {collab.rejectionReason}
              </Text>
            </Box>
          )}
          <Text mb={4}>Please provide links for each deliverable:</Text>
          <VStack spacing={4}>
            {sortedDeliverables.map(([key, links]) => (
              <FormControl key={key}>
                <FormLabel>{`${deliverableNames[key as keyof typeof deliverableNames].plural} (${links.length})`}</FormLabel>
                {links
                  .sort((a, b) => (b.userLink?.length || 0) - (a.userLink?.length || 0))  // Put completed links first
                  .map((link, index) => (
                    <Input
                      key={index}
                      placeholder={`Enter link for ${deliverableNames[key as keyof typeof deliverableNames].singular} #${index + 1}`}
                      value={link.userLink}
                      onChange={(e) => handleInputChange(key, index, e.target.value)}
                      mb={2}
                    />
                  ))}
              </FormControl>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Flex justifyContent="space-between" width="100%">
            <Button 
              bg="gray.300" 
              color="black" 
              onClick={handleSave}
              _hover={{ bg: "gray.300" }}
            >
              Save and Finish Later
            </Button>
            <Button 
              bg="black" 
              color="white" 
              onClick={handleSubmit}
              _hover={{ bg: "gray.800" }}
              isDisabled={!checkAllFieldsFilled()}
            >
              Submit
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SubmitCollabModal;