import React, { createContext, useState, useEffect, ReactNode } from "react";
import { doc, onSnapshot, updateDoc, query, where, collection, getDocs } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';

import { Influencer } from "../types";

interface InfluencerDataContextType {
    influencerData: Influencer | null;
    loading: boolean;
}

export const InfluencerDataContext = createContext<InfluencerDataContextType>({
    influencerData: null,
    loading: true
});

interface InfluencerDataProviderProps {
    children: ReactNode;
}

export const InfluencerDataProvider: React.FC<InfluencerDataProviderProps> = ({ children }) => {
    const [user, loading, error] = useAuthState(auth);
    const [influencerData, setInfluencerData] = useState<Influencer | null>(null);
    const [loadingState, setLoading] = useState<boolean>(true);
    useEffect(() => {
        if (!user) {
            setLoading(false);
            return;
        }
        
        const fetchInfluencerData = async () => {
            const influencersRef = collection(firestore, "influencers");
            const q = user.phoneNumber ? query(influencersRef, where("phoneNumber", "==", user.phoneNumber)) 
                                            : query(influencersRef, where("email_address", "==", user.email));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                const data = doc.data() as Influencer;
                setInfluencerData(data);
            } else {
                console.log("No influencer data found for phone number:", user.phoneNumber);
            }
            setLoading(false);
        };

        fetchInfluencerData();
    }, [user]);

    const value: InfluencerDataContextType = { influencerData, loading: loadingState};

    return (
        <InfluencerDataContext.Provider value={value}>
            {children}
        </InfluencerDataContext.Provider>
    );
};