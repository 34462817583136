import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Flex, FormControl, FormLabel, Input, Link, Checkbox, Text, VStack, useToast, Select } from '@chakra-ui/react';
import { checkInfluencerEmail, sendEmailVerificationCode } from '../../services/firebaseService';
import TermsOfServiceModal from '../../components/TermsOfServiceModal';

const SignUpPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const [countryCode, setCountryCode] = useState<string>('+1');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setEmail(emailParam);
    }
  }, [location]);

  const validateEmail = (email: string): boolean => {
    return email.includes('@');
  };

  const validatePhoneNumber = (phone: string): boolean => {
    const sanitizedPhone = phone.replace(/[^\d+]/g, '');
    return sanitizedPhone.length >= 10 && sanitizedPhone.length <= 14;
  };

  const handleSubmit = async () => {
    if (!agreeToTerms) {
      toast({
        title: "Error",
        description: "Please agree to the terms of service to continue.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!validateEmail(email)) {
      toast({
        title: "Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!await checkInfluencerEmail(email)) {
      toast({
        title: "Error",
        description: "Please sign in with the email where you received your invite.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const fullPhoneNumber = `${countryCode}${phoneNumber}`;
    if (!validatePhoneNumber(fullPhoneNumber)) {
      toast({
        title: "Error",
        description: "Please enter a valid phone number.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const isSuccess = await sendEmailVerificationCode(email, fullPhoneNumber);
      if (isSuccess) {
        navigate('/email-sent');
      } else {
        toast({
          title: "Error",
          description: "Sign up failed. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Sign up error:', error);
      toast({
        title: "Error",
        description: "An error occurred during sign up. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box minH="100vh" display="flex" alignItems="center" justifyContent="center" bg="white" p="4" position="relative">
      <Button 
        onClick={() => navigate('/login')} 
        position="absolute"
        top="16px"
        left="16px"
        variant="ghost"
      >
        &larr;
      </Button>
      <VStack spacing="6" as="form" onSubmit={e => { e.preventDefault(); handleSubmit(); }} width="100%" maxW="400px">
        <Text fontSize="2xl" fontWeight="bold">We're excited to have you!</Text>
        <FormControl isRequired>
          <Input id="email" type="email" bg="#F7F8F9" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
        </FormControl>
        <FormControl isRequired>
          <Flex>
            <Select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              width="31%"
              bg="#F7F8F9"
            >
              <option value="+1">+1</option>
              <option value="+44">+44</option>
              <option value="+33">+33</option>
              <option value="+49">+49</option>
              <option value="+593">+593</option>
            </Select>
            <Input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              bg="#F7F8F9"
            />
          </Flex>
        </FormControl>
        <Flex alignItems="center">
          <Checkbox id="agree-terms" isChecked={agreeToTerms} onChange={e => setAgreeToTerms(e.target.checked)} />
          <Text ml="2">
            I agree to the <Link onClick={() => setIsTermsOpen(true)} textDecoration="underline">terms of service</Link>.
          </Text>
        </Flex>
        <Button type="submit" bg="black" color="white" width="full" mt="280">Submit</Button>
        <Text mt="4">
          Already have an account? <Link color="green" href="/returning-user">Login Now</Link>
        </Text>
      </VStack>
      <TermsOfServiceModal isOpen={isTermsOpen} onClose={() => setIsTermsOpen(false)} />
    </Box>
  );
};

export default SignUpPage;
