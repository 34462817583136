import React from 'react';
import { RouteObject } from 'react-router-dom';
import ReviewCollabsPage from '../features/collabs/pages/ReviewCollabPage';
import CollabsPage from '../features/collabs/index';
import RedeemRewardPage from '../features/collabs/pages/RedeemRewardPage';
import ViewRewardPage from '../features/collabs/pages/ViewRewardPage';

const collabRoutes: RouteObject[] = [
  { path: "review-collab", element: <ReviewCollabsPage /> },
  { path: "redeem-reward", element: <RedeemRewardPage /> },
  { path: "view-reward", element: <ViewRewardPage /> },
];

export default collabRoutes;