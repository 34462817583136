import React, { useState, useEffect, useContext } from 'react';
import { Box, VStack, Text, Button, Flex, Image, useToast } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { redeemCollab } from '../../../services/firebaseService';
import { CollabDataContext } from '../../../contexts/collabDataContext';
import { InfluencerCampaignDataContext } from '../../../contexts/InfluencerCampaignDataContext';
import { InfluencerDataContext } from '../../../contexts/InfluencerDataContext';
import { FaExclamationTriangle } from 'react-icons/fa';

const RedeemRewardPage: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isRedeeming, setIsRedeeming] = useState(false); // New state to track redeeming status

  const collabData = useContext(CollabDataContext);
  const campaignData = useContext(InfluencerCampaignDataContext);
  const { influencerData } = useContext(InfluencerDataContext);

  const collab = collabData.find(c => c.id === id);
  const campaign = id ? campaignData[id] : undefined;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const handleConfirmRedemption = async () => {
    if (!id) return;
    setIsLoading(true);
    setIsRedeeming(true); // Set redeeming status to true
    try {
      await redeemCollab(id);
      toast({
        title: "Reward Redeemed!",
        description: `You've successfully redeemed $${collab?.compensation}. Enjoy!`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate(`/collabs/view-reward?id=${id}`);
    } catch (error) {
      console.error('Error redeeming collab:', error);
      toast({
        title: "Error",
        description: "There was an error redeeming your reward. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      setIsRedeeming(false); // Reset redeeming status
    }
  };

  const handleRedeemLater = () => {
    navigate('/collabs');
  };

  const calculateRemainingTime = (collab) => {
    if (collab.status !== 'pendingRedemption' || !collab.collabAcceptedTime) {
      return '';
    }

    const startTime = collab.collabAcceptedTime.toDate();
    const duration = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
    const endTime = new Date(startTime.getTime() + duration);
    const timeLeft = endTime.getTime() - Date.now();

    if (timeLeft <= 0) {
      return 'Time expired';
    }

    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  if (!collab || !campaign) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box minHeight="100vh" bg="black" display="flex" alignItems="center" justifyContent="center" overflow="hidden">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '0 30px' }}
      >
        <VStack spacing={8} align="center" width="100%" maxWidth="450px">
          <Text fontSize="2xl" color="gray.300" textAlign="center">
            Ready to indulge at {campaign.clientName || 'the restaurant'}?
          </Text>
          <Box position="relative" width="100%" overflow="hidden" borderRadius="md">
            {campaign.collabImage && (
              <Image 
                src={campaign.collabImage} 
                alt="Collab Image" 
                objectFit="cover" 
                width="100%" 
                height="200px"
              />
            )}
            <Box 
              position="absolute" 
              top="0"
              left="0" 
              right="0"
              bottom="0"
              bg="rgba(0,0,0,0.5)"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              px={4}
            >
              <Text fontSize="3xl" fontWeight="bold" color="white" mb={4} textAlign="center">
                ${collab.compensation} {campaign.rewardType === 'diningCredit' ? 'Dining Credit' : 'Cash'}
              </Text>
            </Box>
          </Box>
          <Box>
          <Flex align="center" justify="center" fontSize="24px" color="white" textAlign="center" mx={10} mb={4}>
              <Text>Only continue if you are at the restaurant!</Text>
            </Flex>
            <Text fontSize="lg" color="white" textAlign="center" mx={10} mb={4}>
              You can only confirm a credit redemption once.
            </Text>
          </Box>
          <Flex direction="column" width="100%">
            {isRedeeming ? ( // Conditional rendering based on redeeming status
              <>
                <Button
                  bg="purple.500"
                  color="white"
                  _hover={{ bg: "purple.600" }}
                  mb={4}
                  onClick={handleConfirmRedemption}
                  isLoading={isLoading}
                  loadingText="Confirming..."
                >
                  Confirm Redeem
                </Button>
                <Button
                  variant="outline"
                  color="gray.300"
                  borderColor="gray.300"
                  _hover={{ bg: "gray.800" }}
                  onClick={() => setIsRedeeming(false)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  bg="purple.500"
                  color="white"
                  _hover={{ bg: "purple.600" }}
                  mb={4}
                  onClick={() => setIsRedeeming(true)}
                  isLoading={isLoading}
                  loadingText="Confirming..."
                >
                  Redeem Now
                </Button>
                <Button
                  variant="outline"
                  color="gray.300"
                  borderColor="gray.300"
                  _hover={{ bg: "gray.800" }}
                  onClick={handleRedeemLater}
                >
                  I'll Come Back Later
                </Button>
              </>
            )}
          </Flex>
          <Text fontSize="md" color="gray.500" textAlign="center" mt={4}>
            Expires in: {calculateRemainingTime(collab)}
          </Text>
        </VStack>
      </motion.div>
    </Box>
  );
};

export default RedeemRewardPage;