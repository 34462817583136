import React, { createContext, useState, useEffect, useContext, ReactNode } from "react";
import { doc, getDoc, DocumentData } from "firebase/firestore";
import { firestore } from "../firebase";
import { CollabDataContext } from "./collabDataContext";
import { Collab, Campaign } from "../types";

interface CampaignDataContextType {
    [collabId: string]: Campaign & {
        clientName: string;
        instagramHandle: string;
        tiktokHandle: string;
    };
}

export const InfluencerCampaignDataContext = createContext<CampaignDataContextType>({});

interface InfluencerCampaignDataProviderProps {
    children: ReactNode;
}

export const InfluencerCampaignDataProvider: React.FC<InfluencerCampaignDataProviderProps> = ({ children }) => {
    const collabData = useContext(CollabDataContext);
    const [campaignData, setCampaignData] = useState<CampaignDataContextType>({});

    useEffect(() => {
        const fetchCampaignData = async () => {
            const campaignPromises = collabData.map(async (collab) => {
                if (collab.influencerCampaignID) {
                    const campaignDocRef = doc(firestore, "influencerCampaigns", collab.influencerCampaignID);
                    const campaignDocSnap = await getDoc(campaignDocRef);
                    if (campaignDocSnap.exists()) {
                        const campaignData = campaignDocSnap.data() as Campaign;
                        const clientDocRef = doc(firestore, "clients", campaignData.clientID);
                        const clientDocSnap = await getDoc(clientDocRef);
                        if (clientDocSnap.exists()) {
                            const clientData = clientDocSnap.data();
                            return {
                                [collab.id]: {
                                    ...campaignData,
                                    clientName: clientData.accountName || "",
                                    instagramHandle: clientData.instagramHandle || "",
                                    tiktokHandle: clientData.tiktokHandle || ""
                                }
                            };
                        }
                    }
                }
                return null;
            });

            const campaignResults = await Promise.all(campaignPromises);
            const campaigns = campaignResults.reduce((acc, result) => {
                if (result) {
                    return { ...acc, ...result };
                }
                return acc;
            }, {});

            setCampaignData(campaigns);
        };

        fetchCampaignData();
    }, [collabData]);

    return (
        <InfluencerCampaignDataContext.Provider value={campaignData}>
            {children}
        </InfluencerCampaignDataContext.Provider>
    );
};