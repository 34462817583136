import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  Heading,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';

interface TermsOfServiceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsOfServiceModal: React.FC<TermsOfServiceModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>Terms of Service</ModalHeader>
        <ModalBody>
          <Text>
            <Heading size="md">Terms and Conditions</Heading>
            Foodfluence, Inc. Terms of Service for Influencers

            <Heading size="md">Effective Date: 09/16/2024</Heading>

            FOODFLUENCE, INC. ("Foodfluence" or "we" or "us" or "our") provides a web-based platform for directly connecting local businesses and influencers (the “Services”).

            <Heading size="md">The Foodfluence Services</Heading>
            Through the Foodfluence Services, influencers are informed of local businesses (each, a “Business”) seeking influencers for promotional purposes. These promotions may include (without limitation) visiting the business and posting about it on social media platforms in exchange for dining credits or cash (each, an “Engagement”). When a Business offers an Engagement, it will provide details (e.g., date and time, terms and conditions, offers, etc.), criteria for influencers, and any associated compensation offered (“Offers”). If you meet the criteria and are interested, you can accept the Offer via the App. It will be your responsibility to comply with all stated terms and conditions of each Offer, which may include coordinating with the Business prior to the Engagement. We reserve the right to limit access to certain Offers at our discretion. Should you encounter any inappropriate behavior from a Business, please contact us immediately at contact@foodfluence.io.

            <Heading size="md">Conditions of Use</Heading>
            You may not use the Foodfluence Services unless (i) you are at least 18 years old; and (ii) you agree to these Terms, which may be modified at any time. If you disagree with any modifications, your only remedy is to immediately discontinue use of the Foodfluence Services.

            <Heading size="md">Acceptance</Heading>
            BY ACCESSING OR USING THE FOODFLUENCE SERVICES, YOU AGREE TO BE BOUND BY ALL OF THE TERMS HEREIN, INCLUDING YOUR AGREEMENT TO SUBMIT ANY DISPUTES TO BINDING ARBITRATION. Please read all the terms before using the Foodfluence Services. If any term does not make sense to you, please let us know.

            <Heading size="md">Modifications</Heading>
            We reserve the right to change these Terms from time to time. If changes are made, we will notify you by revising the date at the top of the Terms (and, in some cases, providing additional notice such as adding a statement to our homepage or sending an email notification). Users should review the Terms periodically to stay informed of updates. Changes to these Terms will be effective when posted, and your continued use of Foodfluence Services after the effective date of any revisions signifies your acceptance of the changes.

            <Heading size="md">Privacy Policy</Heading>
            For information on how we collect, use, and share information about users of the Foodfluence Services, please see our Privacy Policy at [foodfluence.io/Privacy-Policy](#).

            <Heading size="md">Accounts</Heading>
            To use the Foodfluence Services, you must create an account, which may require linking to your social media accounts (e.g., Instagram). You are responsible for all activity on your account and should keep your log-in credentials secure. By creating an account, you represent and warrant that (i) you are at least 18 years old, (ii) all information provided is truthful, accurate, current, and complete, and (iii) your use of the Foodfluence Services will not violate any third-party contractual obligations. Foodfluence reserves the right to deny or terminate any account at our discretion.

            <Heading size="md">Offers</Heading>
            Foodfluence makes no guarantees regarding Offers, which originate from Businesses. If a Business fails to honor its Offer, please report it to Foodfluence immediately. Your participation in Engagements may be monitored through mechanisms such as geolocation from your mobile device. If an Engagement requires your physical presence at a location, you must present any necessary information to the Business upon arrival. If you fail to meet the Business’s expectations, Offers may be reduced or withheld entirely.

            <Heading size="md">Payments</Heading>
            For certain Engagements, Foodfluence may collect compensation directly from the Businesses and remit your share to you. The following terms apply:

            <OrderedList>
              <ListItem>Payments will be made only if the Business pays Foodfluence. Foodfluence will not be obligated to pay you unless we receive payment from the Business in full.</ListItem>
              <ListItem>All payments will be made using the payment solution Stripe (or another authorized payment solution). You must have a valid account with Stripe to receive payments from Foodfluence.</ListItem>
              <ListItem>Foodfluence is not your employer and will not withhold taxes from payments. You are solely responsible for any tax liabilities.</ListItem>
            </OrderedList>

            <Heading size="md">No Agency</Heading>
            Foodfluence does not appoint you as an employee or legal agent, nor does it form a partnership or joint venture with you. You are not authorized to make any commitments on behalf of Foodfluence, and we will not make any on your behalf, except as expressly authorized through the Foodfluence Services.

            <Heading size="md">Right to Use the Foodfluence Services</Heading>
            Subject to your compliance with these Terms, Foodfluence grants you a limited, nonexclusive, non-transferable, and revocable license to access and use the Foodfluence Services for your personal use.

            <Heading size="md">Prohibited Use of the Foodfluence Services</Heading>
            You agree not to:

            <OrderedList>
              <ListItem>Interfere with or disrupt the Foodfluence Services.</ListItem>
              <ListItem>Collect any personal information about other users.</ListItem>
              <ListItem>Harass, threaten, or intimidate other users.</ListItem>
              <ListItem>Circumvent any security measures of the Foodfluence Services.</ListItem>
              <ListItem>Use the Foodfluence Services for illegal or unauthorized purposes.</ListItem>
            </OrderedList>

            <Heading size="md">Release of Liability</Heading>
            YOUR PARTICIPATION IN AN ENGAGEMENT IS VOLUNTARY, AND YOU ACCEPT ALL ASSOCIATED RISKS. YOU RELEASE FOODFLUENCE, ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS ARISING FROM YOUR PARTICIPATION IN AN ENGAGEMENT.

            <Heading size="md">Third Party Software and Links</Heading>
            The App and the Site may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. Foodfluence does not endorse any such sites or the information, materials, products, or services contained on or accessible through such sites. Foodfluence has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. If you access a third-party website from the App or the Site, you do so at your own risk, and you understand that these Terms do not apply to your use of such sites. You expressly relieve Foodfluence from any and all liability arising from your use of any third-party website, services, or third-party-owned content.

            <Heading size="md">Changes to the Services</Heading>
            Foodfluence reserves the right to review, improve, change, or discontinue, temporarily or permanently, the Foodfluence Services and/or any features, information, materials, or content on the Foodfluence Services with or without providing notice to you. Foodfluence will not be liable to you or any third party for any changes or discontinuance of the Foodfluence Services or any part of the Foodfluence Services.

            <Heading size="md">Consent to Electronic Communications</Heading>
            By using the Foodfluence Services, you agree that we may communicate with you electronically regarding your use of the Foodfluence Services and that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that the communications be in writing. To withdraw your consent from receiving electronic notice, please notify us at contact@foodfluence.io.

            <Heading size="md">Suspension and Termination</Heading>
            Foodfluence may suspend or terminate your rights to access or use the Foodfluence Services (including the App) for any reason at all, with or without notice, at Foodfluence’s sole discretion. Suspension or termination may include restricting access to and use of the App. All of the terms of these Terms (excluding the license grant) will survive any termination or suspension. Termination of access to the Foodfluence Services will not release either party from any obligations incurred prior to such termination, and Foodfluence may retain and continue to use for its own internal purposes any information previously provided by you.

            <Heading size="md">Governing Law; Arbitration</Heading>
            These Terms are governed by and construed in accordance with the laws of the State of Texas. Any disputes will be resolved through binding arbitration in Texas, in accordance with the rules of the American Arbitration Association.

            <Heading size="md">No Circumvention</Heading>
            Any attempt to circumvent Foodfluence (e.g., by dealing with Businesses directly for the purpose of avoiding the use of the Foodfluence Services) is prohibited and may result in the termination of your account and/or access to the Foodfluence Services.

            <Heading size="md">General</Heading>
            Enforcement of these Terms is solely at Foodfluence's discretion. Failure to enforce any part of these Terms in some instances does not constitute a waiver of

            our right to enforce the same or other part of these Terms in other instances. If any provision of these Terms is or becomes unlawful, void, or otherwise unenforceable, then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remaining provisions of these Terms will continue in full force and effect.

            <Heading size="md">Contact Us</Heading>
            If you have any questions about these Terms, please email us at contact@foodfluence.io
          </Text>
        </ModalBody>
        <Button onClick={onClose}>Close</Button>
      </ModalContent>
    </Modal>
  );
};

export default TermsOfServiceModal;