import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  VStack,
  Image,
  Box
} from '@chakra-ui/react';

import intro1 from '../assets/intro/people-celebrating.png';
import intro2 from '../assets/intro/collab-card.png';
import intro3 from '../assets/intro/collab-details.png';
import intro4 from '../assets/intro/dining-person.png';

interface HelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpModal: React.FC<HelpModalProps> = ({ isOpen, onClose }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const helpPages = [
    {
      title: "Welcome to FoodFluence",
      content: "We are so excited to have you! Let's quickly go over how the platform works, and what you should expect.",
      image: intro1
    },
    {
      title: "See pending collaborations from restaurants & brands",
      image: intro2
    },
    {
      title: "Accept Collaborations",
      image: intro3
    },
    {
      title: "Follow the steps, visit, get rewarded, and post!",
      image: intro4
    }
  ];

  const handleNext = () => {
    if (currentPage < helpPages.length - 1) {
      setCurrentPage(currentPage + 1);
    } else {
      onClose();
    }
  };
  useEffect(() => {
    if (isOpen) {
      setCurrentPage(0);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
      <ModalOverlay />
      <ModalContent 
        height="100vh" 
        width="100vw" 
        margin={0} 
        borderRadius={0} 
        bg={currentPage < 2 ? "black" : "white"}
        marginTop={10}
      >
        <ModalHeader color={currentPage < 2 ? "white" : "black"} fontSize="4xl" fontWeight="bold" textAlign="center" mt={10} pb={0}>{helpPages[currentPage].title}</ModalHeader>
        <ModalCloseButton color="#616161" />
        <ModalBody pt={0}>
            <Box display="flex" flexDirection="column" alignItems="center" height="100%">
              {helpPages[currentPage].content && (
                <Text textAlign="center" color={currentPage < 2 ? "white" : "black"} mt={5}>
                  {helpPages[currentPage].content}
                </Text>
              )}
              <Image 
                src={helpPages[currentPage].image} 
                boxSize="300px" 
                objectFit="contain"
                width="300px"
                height="400px"
              />
            </Box>
        </ModalBody>
        <ModalFooter>
          <Button 
            bg={currentPage < 2 ? "white" : "black"} 
            color={currentPage < 2 ? "black" : "white"} 
            onClick={handleNext} 
            width="calc(100% - 20px)"
            mx="auto"
            height="55px"
            mb={10}
            _active={{ bg: currentPage < 2 ? "white" : "black" }}
            _hover={{ bg: currentPage < 2 ? "white" : "black" }}
          >
            {currentPage === 0 ? "Get Started" : "Continue"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default HelpModal;