import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  Link,
  Button,
} from '@chakra-ui/react';

interface ExpiredOfferModalProps {
  isOpen: boolean;
  onClose: () => void;
  campaignName: string;
}

const ExpiredOfferModal: React.FC<ExpiredOfferModalProps> = ({ isOpen, onClose, campaignName }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="white" borderRadius="xl" boxShadow="xl" p={6}>
        <ModalHeader textAlign="center" fontSize="2xl" fontWeight="bold" color="red.500">
          Offer Expired
        </ModalHeader>
        <ModalBody>
          <Text textAlign="center" fontSize="lg" mb={4}>
            Unfortunately, the offer for {campaignName} has expired. Please reach out if you have any questions.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button
            bg="black"
            color="white"
            _hover={{ bg: "gray.800" }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExpiredOfferModal;