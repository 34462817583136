import React, { useContext, useState } from 'react';
import { Box, VStack, Flex, Button, IconButton, useToast } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import CreativeBrief from '../components/creativeBrief';
import { acceptCollab, rejectCollab } from '../../../services/firebaseService';
import { CollabDataContext } from '../../../contexts/collabDataContext';
import { InfluencerCampaignDataContext } from '../../../contexts/InfluencerCampaignDataContext';

const ReviewCollabsPage: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  
  const toast = useToast();

  const collabData = useContext(CollabDataContext);
  const campaignData = useContext(InfluencerCampaignDataContext);

  const collab = collabData.find(c => c.id === id);
  const campaign = id ? campaignData[id] : undefined;

  const [isRejecting, setIsRejecting] = useState(false);

  const handleAccept = async () => {
    try {
      if (!collab || !id) {
        throw new Error("Collab not found");
      }
      await acceptCollab(id, collab.deliverableCounts);
      toast({
        title: "Collab accepted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error accepting collab:', error);
      toast({
        title: "Error accepting collab",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const handleReject = async () => {
    if (!isRejecting) {
      setIsRejecting(true);
      return;
    }

    try {
      if (!id) throw new Error("Collab ID is undefined");
      await rejectCollab(id);
      toast({
        title: "Collab rejected",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate('/collabs');
    } catch (error) {
      console.error('Error rejecting collab:', error);
      toast({
        title: "Error rejecting collab",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!collab || !campaign) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box minH="100vh" bg="white" p={4} display="flex" flexDirection="column">
      <IconButton
        icon={<ArrowBackIcon boxSize="24px"/>}
        onClick={() => navigate('/collabs')}
        position="absolute"
        top={4}
        left={4}
        aria-label="Go back"
        bg="white"
        color="black"
        _hover={{ bg: "gray.100" }}
      />
      <VStack spacing={6} align="stretch" flex={1} mt={1}>
        <CreativeBrief collab={collab} campaign={campaign} />
      </VStack>
      <Flex justifyContent="space-between" mt={6} mb={8}>
        {isRejecting ? (
          <>
            <Button
              bg="white"
              color="black"
              border="1px"
              borderColor="black"
              _hover={{ bg: "gray.100" }}
              onClick={() => setIsRejecting(false)}
              flex={1}
              mr={2}
            >
              Cancel
            </Button>
            <Button
              bg="red.500"
              color="white"
              border="1px"
              borderColor="red.500"
              _hover={{ bg: "red.600" }}
              onClick={handleReject}
              flex={1}
              ml={2}
            >
              Confirm Reject
            </Button>
          </>
        ) : (
          <>
            <Button
              bg="white"
              color="black"
              border="1px"
              borderColor="black"
              _hover={{ bg: "gray.100" }}
              onClick={handleReject}
              flex={1}
              mr={2}
            >
              Reject
            </Button>
            <Button
              bg="black"
              color="white"
              _hover={{ bg: "gray.800" }}
              onClick={handleAccept}
              flex={1}
              ml={2}
            >
              Accept
            </Button>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default ReviewCollabsPage;