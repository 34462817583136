import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Text, VStack, Flex, Image, Center, Input, FormControl, FormLabel, useToast, Select, Avatar, IconButton, Icon, Link, CloseButton} from '@chakra-ui/react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { InfluencerDataContext } from '../contexts/InfluencerDataContext';
import { updateEmailAddress, updateFirstName, updateLastName, updatePhoneNumber } from '../services/firebaseService';
import { QuestionIcon } from '@chakra-ui/icons';
import HelpModal from 'components/HelpModal';
import { MdArrowForward, MdLiveHelp } from 'react-icons/md';

const ProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { influencerData } = useContext(InfluencerDataContext);

  // Separate country code and phone number
  const initialPhoneNumber = influencerData?.phoneNumber || '';
  const initialCountryCode = initialPhoneNumber.startsWith('+') ? initialPhoneNumber.slice(0, 2) : '+1';
  const initialPhone = initialPhoneNumber.startsWith('+') ? initialPhoneNumber.slice(2) : initialPhoneNumber;

  const [newPhoneNumber, setNewPhoneNumber] = useState<string>(initialPhone);
  const [countryCode, setCountryCode] = useState<string>(initialCountryCode);
  const [newFirstName, setNewFirstName] = useState<string>(influencerData?.firstName || '');
  const [newLastName, setNewLastName] = useState<string>(influencerData?.lastName || '');
  const [newEmail, setNewEmail] = useState<string>(influencerData?.email_address || '');
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [showFeaturedBanner, setShowFeaturedBanner] = useState<boolean>(true);
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);

  useEffect(() => {
    const hasChanged = 
      `${countryCode}${newPhoneNumber}` !== influencerData?.phoneNumber ||
      newFirstName !== influencerData?.firstName ||
      newLastName !== influencerData?.lastName ||
      newEmail !== influencerData?.email_address;
    setIsChanged(hasChanged);
  }, [newPhoneNumber, countryCode, newFirstName, newLastName, newEmail, influencerData]);

  const handleSignOut = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      navigate('/'); // Redirect to home or login page after sign out
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleIntercom = () => {
    if (isIntercomOpen) {
      window.Intercom('hide');
    } else {
      window.Intercom('show');
    }
    setIsIntercomOpen(!isIntercomOpen);
  };

  const handleUpdateProfile = async () => {
    if (influencerData) {
      try {
        // Remove dashes, parentheses, and spaces
        const sanitizedPhoneNumber = newPhoneNumber.replace(/[-()\s]/g, '');
        const fullPhoneNumber = `${countryCode}${sanitizedPhoneNumber}`;

        // Check if the phone number is longer than 10 digits
        if (sanitizedPhoneNumber.length !== 10) {
          toast({
            title: "Error",
            description: "Phone number cannot be longer than 14 digits.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        // Check if the email contains an @ symbol
        if (!newEmail.includes('@')) {
          toast({
            title: "Error",
            description: "Email must contain an '@' symbol.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        if (fullPhoneNumber !== influencerData?.phoneNumber) {
          await updatePhoneNumber(influencerData.instagramId, fullPhoneNumber);
          influencerData.phoneNumber = fullPhoneNumber;
        }
        if (newFirstName !== influencerData?.firstName) {
          await updateFirstName(influencerData.instagramId, newFirstName);
          influencerData.firstName = newFirstName;
        }
        if (newLastName !== influencerData?.lastName) {
          await updateLastName(influencerData.instagramId, newLastName);
          influencerData.lastName = newLastName;
        }
        if (newEmail !== influencerData?.email_address) {
          await updateEmailAddress(influencerData.instagramId, newEmail);
          influencerData.email_address = newEmail;
        }
        toast({
          title: "Success",
          description: "Profile updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsChanged(false);
        // window.location.reload();
      } catch (error) {
        console.error('Error updating profile:', error);
        toast({
          title: "Error",
          description: "Failed to update profile. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <VStack spacing={4}>
      <Center>
      <Avatar
            src={influencerData?.profile_pic_url}
            name={influencerData?.firstName || ''}
            size="md"
            mb={4}
            boxSize="150px"
            objectFit="cover"
            borderRadius="full"
          />
      </Center>
      <FormControl id="firstname">
        <FormLabel>First Name</FormLabel>
        <Input
          type="text"
          value={newFirstName}
          onChange={(e) => setNewFirstName(e.target.value)}
        />
      </FormControl>
      <FormControl id="lastname">
        <FormLabel>Last Name</FormLabel>
        <Input
          type="text"
          value={newLastName}
          onChange={(e) => setNewLastName(e.target.value)}
        />
      </FormControl>
      <FormControl id="phoneNumber">
        <FormLabel>Phone Number</FormLabel>
        <Flex>
          <Select
            value={countryCode}
            onChange={(e) => setCountryCode(e.target.value)}
            width="40%"
          >
            <option value="+1">+1</option>
            <option value="+44">+44</option>
            <option value="+33">+33</option>
            <option value="+49">+49</option>
          </Select>
          <Input
            type="tel"
            value={newPhoneNumber}
            onChange={(e) => setNewPhoneNumber(e.target.value)}
          />
        </Flex>
      </FormControl>
      
      {
      
      /*
      Not allowing users to change their email 
      because it will be changed back by scraper
       <FormControl id="email">
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
      </FormControl> */}
      <Button bg="#1A202C" color="white" onClick={handleUpdateProfile} isDisabled={!isChanged}>
        Update Profile
      </Button>
      <Button colorScheme="red" onClick={handleSignOut}>Sign Out</Button>
      <IconButton
        icon={<QuestionIcon />}
        aria-label="Help"
        position="fixed"
        top={4}
        right={4}
        variant="ghost"
        borderRadius="full"
        onClick={() => setIsHelpModalOpen(true)}
      />
       {showFeaturedBanner && (
            <Box width="100%" mt={4} py={3} pl={6} pr={6} bg="linear-gradient(90deg, #1A202C 0%, #2D3748 100%)" borderRadius="md" boxShadow="md" position="relative">
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="sm" fontWeight="medium" color="white">Want to stand out? Become a featured influencer!</Text>
                <Link href="https://form.typeform.com/to/sde7Z1zY">
                  <Icon as={MdArrowForward} color="white" boxSize={5} />
                </Link>
              </Flex>
            </Box>
          )}
      <Button
        position="fixed"
        bottom="20px"
        right="20px"
        borderRadius="full"
        width="50px"
        height="50px"
        bg="black"
        color="white"
        onClick={toggleIntercom}
      >
        <Icon as={MdLiveHelp} boxSize={6} />
      </Button>
      <HelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} />
    </VStack>
  );
};

export default ProfilePage;