import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { InfluencerDataProvider } from './contexts/InfluencerDataContext';
import { CollabDataProvider } from './contexts/collabDataContext';
import { InfluencerCampaignDataProvider } from './contexts/InfluencerCampaignDataContext';
import NewLoginPage from 'features/authentication/NewLogin';
import ReturningUser from 'features/authentication/ReturningUser'; // Import ReturningUser component
import CollabsPage from './features/collabs/index';
import collabRoutes from './routes/collabRoutes';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import { isSignInWithEmailLink } from 'firebase/auth';
import Verification from 'features/authentication/Verification';
import { VerificationProvider } from 'contexts/PhoneVerificationContext';
import RecaptchaInitializer from 'features/collabs/components/RecaptchaInitializer';
import RedeemRewardPage from './features/collabs/pages/RedeemRewardPage';
import ViewRewardPage from './features/collabs/pages/ViewRewardPage';
import SignUpPage from 'features/authentication/NewSignup'; // Import NewSignup component
import EmailSentPage from 'features/authentication/EmailSentPage'; 

const App: React.FC = () => {
  const [user] = useAuthState(auth);
  const isEmailSignInLink = isSignInWithEmailLink(auth, window.location.href);
  return (
    <>
      <RecaptchaInitializer />
      <InfluencerDataProvider>
        <CollabDataProvider>
          <InfluencerCampaignDataProvider> 
            <VerificationProvider>
              <Routes>
                <Route path="/" element={user ? <Navigate to="/collabs" replace /> : <Navigate to="/login" replace />} />
                <Route path="login" element={!user ? <NewLoginPage /> : <Navigate to="/collabs" replace />} />
                <Route path="new-signup" element={!user ? <SignUpPage /> : <Navigate to="/collabs" replace />} /> {/* Add route for NewSignup */}
                <Route path="returning-user" element={!user ? <ReturningUser /> : <Navigate to="/collabs" replace />} /> {/* Add route for ReturningUser */}
                <Route path="verification" element={!user ? <Verification /> : <Navigate to="/collabs" replace />} /> {/* Add route for Verification */}
                <Route path="email-sent" element={!user ? <EmailSentPage /> : <Navigate to="/collabs" replace />} /> {/* Add route for EmailSentPage */}

                <Route path="collabs">
                  <Route index element={user || isEmailSignInLink ? <CollabsPage isFirstTime={!user && isEmailSignInLink}/> : <Navigate to="/login" replace />} />
                  {collabRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                  ))}
                </Route>
                <Route path="/redeem-reward" element={<RedeemRewardPage />} />
                <Route path="/view-reward" element={<ViewRewardPage />} />
              </Routes>
            </VerificationProvider>
          </InfluencerCampaignDataProvider>
        </CollabDataProvider>
      </InfluencerDataProvider>
    </>
  );
}

export default App;
