import React, { useContext, useState } from 'react';
import { Box, Text, Image, Flex, Button, Link, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import SubmitCollabModal from './SubmitCollabModal';
import VerificationPendingModal from './VerificationPendingModal';
import CreativeBriefModal from './CreativeBriefModal';
import deliverableNames from '../../../utils/deliverableNames';
import { Collab, Campaign } from '../../../types';
import { expireCollab } from '../../../services/firebaseService';
import defaultImage from '../../../assets/foodfluence_default_image.jpg';
import { InfluencerDataContext } from '../../../contexts/InfluencerDataContext';


interface CollabComponentProps {
  collab: Collab;
  campaign: Campaign | null;
}

const CollabComponent: React.FC<CollabComponentProps> = ({ collab, campaign }) => {
  const navigate = useNavigate();
  const [isSubmitCollabModalOpen, setIsSubmitCollabModalOpen] = useState(false);
  const [isVerificationPendingModalOpen, setIsVerificationPendingModalOpen] = useState(false);
  const [isCreativeBriefModalOpen, setIsCreativeBriefModalOpen] = useState(false);
  const { influencerData } = useContext(InfluencerDataContext);

  const handleCollabClick = () => {
    switch (collab.status) {
      case 'pendingInfluencerApproval':
        navigate(`/collabs/review-collab?id=${collab.id}`);
        break;
      case 'pendingSubmission':
      case 'pendingResubmission':
        setIsSubmitCollabModalOpen(true);
        break;
      case 'pendingRedemption':
        navigate(`/collabs/redeem-reward?id=${collab.id}`);
        break;
      case 'collabRedeemed':
        navigate(`/collabs/view-reward?id=${collab.id}`);
        break;
      case 'pendingVerification':
        setIsVerificationPendingModalOpen(true);
        break;
    }
  };

  const handleViewCreativeBrief = () => {
    setIsCreativeBriefModalOpen(true);
  };

  const actionNames = {
    pendingInfluencerApproval: "Review Collab",
    pendingSubmission: "Complete Now",
    pendingVerification: "Awaiting Verification",
    pendingResubmission: "Resubmit Content",
    pendingRedemption: "Redeem Reward",
    collabRedeemed: "View Reward",
  }

  const formatDate = (date: any) => {
    if (date && date.toDate instanceof Function) {
      return date.toDate();
    }
    return null;
  };

  const calculateRemainingTime = (collab: Collab) => {
    if (collab.status !== 'pendingInfluencerApproval' && collab.status !== 'pendingRedemption' && collab.status !== 'collabRedeemed') {
      return '';  // Return empty string for other states
    }

    let startTime, duration;
    if (collab.status === 'pendingInfluencerApproval') {
      startTime = formatDate(collab.collabReceivedTime);
      duration = 72 * 60 * 60 * 1000; // 72 hours in milliseconds
    } else if (collab.status === 'pendingRedemption') {
      startTime = formatDate(collab.collabAcceptedTime);
      duration = 7 * 24 * 60 * 60 * 1000; // 1 week in milliseconds
    } else if (collab.status === 'collabRedeemed') {
      startTime = formatDate(collab.collabRedeemedTime);
      duration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    }

    if (!startTime || !duration) {
      return null;
    }

    const endTime = new Date(startTime.getTime() + duration);
    const timeLeft = endTime.getTime() - Date.now();

    if (timeLeft <= 0 && (collab.status === 'pendingInfluencerApproval' || collab.status==="pendingRedemption" || collab.status==="collabRedeemed")) {
      expireCollab(collab.id);
      return 'Time expired';
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0) {
      return `${days}d ${hours}h`;
    } else {
      return `${hours}h ${minutes}m`;
    }
  };

  return (
    <>
      <Box 
        borderWidth={1} 
        borderRadius="xl" 
        overflow="hidden" 
        boxShadow="md"  
        bg="white" 
        width="275px"
        height="275px" 
        display="flex"
        flexDirection="column"
        m={2}
        // cursor="pointer" 
        // _hover={{ boxShadow: "lg" }}
      >
        <Box position="relative">
          <Image src={campaign?.collabImage ?? defaultImage} height="120px" width="100%" objectFit="cover"/>
          {(collab.status === 'pendingInfluencerApproval' || collab.status === 'pendingRedemption' || collab.status === 'collabRedeemed') && (
            <Text 
              position="absolute" 
              top="2" 
              right="2" 
              bg="rgba(0,0,0,0.6)" 
              color="white" 
              px="2" 
              py="1" 
              borderRadius="md" 
              fontSize="xs"
              fontWeight="bold"
            >
              {calculateRemainingTime(collab)}
            </Text>
          )}
        </Box>
        <VStack p={3} flex={1} alignItems="stretch" spacing={2}>
          <Flex direction="row" justifyContent="space-between">
            <Text fontSize="md">{campaign?.clientName}</Text>
            <Text color="gray.600" fontSize="md" >${collab.compensation}</Text>
          </Flex>
          <Text color="gray.600" fontSize="sm" whiteSpace="normal" wordBreak="break-word">
            Deliverables: {Object.entries(collab.deliverableCounts ?? {})
              .filter(([_, count]) => count > 0)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([type, count]) => `${count}x ${deliverableNames[type].short}`)
              .join(' ')}
          </Text>
          <Flex mt="auto" direction="row" alignItems="center" justifyContent={"space-between"}>
            <Button bg="#C7F5C2A6" color="black" size="xs" onClick={handleCollabClick} p={3} alignSelf="flex-start">{actionNames[collab.status]}</Button>
            {collab.status !== 'pendingInfluencerApproval' && (
              <Link
                textDecoration="underline"
                color="black"
                fontSize="sm"
                mb={2}
                onClick={handleViewCreativeBrief}
              >
                Collab Details
              </Link>
            )}
          </Flex>
        </VStack>
      </Box>
      <SubmitCollabModal
        isOpen={isSubmitCollabModalOpen}
        onClose={() => setIsSubmitCollabModalOpen(false)}
        collab={collab}
      />
      <VerificationPendingModal
        isOpen={isVerificationPendingModalOpen}
        onClose={() => setIsVerificationPendingModalOpen(false)}
        clientName={campaign?.clientName ?? ''}
      />
      <CreativeBriefModal
        isOpen={isCreativeBriefModalOpen}
        onClose={() => setIsCreativeBriefModalOpen(false)}
        collab={collab}
        campaign={campaign}
      />
    </>
  );
}

export default CollabComponent;