import React, { useContext } from 'react';
import { VStack, Heading, Text, Box, Flex, Button, Icon } from '@chakra-ui/react';
import { FaInstagram, FaTiktok, FaHamburger } from 'react-icons/fa';
import { CopyIcon } from '@chakra-ui/icons';
import { PiCoin } from "react-icons/pi";
import { Collab, Campaign } from '../../../types';

interface CreativeBriefProps {
  collab: Collab;
  campaign: Campaign;
}

const CreativeBrief: React.FC<CreativeBriefProps> = ({ collab, campaign }) => {
  const deliverableTypeToTask: { [key: string]: string } = {
    instagramReels: "Post/Tag on Instagram Reels",
    instagramStories: "Post/Tag on Instagram Story",
    tiktoks: "Post/Tag on TikTok",
    instagramPosts: "Post/Tag on Instagram Feed"
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };
  
  const formatDate = (date: any) => {
    if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return 'Invalid Date';
  };

  const getCompensationString = (compensation: number, rewardType: string) => {
    return `$${compensation} ${rewardType === 'diningCredit' ? 'Dining Credit' : 'Cash'}`;
  };

  return (
    <VStack align="stretch" spacing={5}>
      <Heading fontSize="2xl" textAlign="center">Collaboration Details</Heading>
      <Box>
        <Text fontWeight="bold" fontSize={"20px"}>Brief Description</Text>
        <Text fontSize={"16px"}>{campaign.description}</Text>
      </Box>
      <Flex direction="column" align="flex-start">
        <Text fontSize="20px" fontWeight="bold">Compensation</Text>
        <Flex align="center" mt={2} width="100%">
          <Flex
            direction="row"
            borderWidth={1}
            borderColor="gray.300"
            borderRadius="md"
            px={3}
            py={2}
            align="center"
            width="100%"
          >
            {collab.rewardType === 'cash' ? (
              <Icon as={PiCoin} mr={4} boxSize={6} />
            ) : collab.rewardType === 'diningCredit' ? (
              <Icon as={FaHamburger} mr={4} boxSize={6} />
            ) : null}
            <Text fontSize="20px" fontWeight={"bold"}>{getCompensationString(collab.compensation, collab.rewardType)}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Box>
      <Text fontWeight="bold" fontSize={"20px"}>Deliverables</Text>
      <Box mt={2} borderWidth={1} borderColor="gray.300" borderRadius="md" p={3}>
        <VStack align="stretch" spacing={3}>
          {campaign?.instagramHandle && (
            <Flex align="center">
              <Icon as={FaInstagram} mr={2} />
              <Text fontSize={"16px"}>Tag @{campaign.instagramHandle}</Text>
              <Button size="sm" ml={2} onClick={() => handleCopy(`@${campaign.instagramHandle}`)} variant="ghost">
                <CopyIcon />
              </Button>
            </Flex>
          )}
          {campaign?.tiktokHandle && (
            <Flex align="center">
              <Icon as={FaTiktok} mr={2} />
              <Text fontSize={"16px"}>Tag @{campaign.tiktokHandle}</Text>
              <Button size="sm" ml={2} onClick={() => handleCopy(`@${campaign.tiktokHandle}`)} variant="ghost">
                <CopyIcon />
              </Button>
            </Flex>
          )}
            <VStack align="stretch" spacing={3}>
              {Object.entries(collab.deliverableCounts).filter(([key, value]) => value > 0).map(([key, value]) => (
                <Flex key={key} align="center">
                  <Text as="span" fontWeight="bold" fontSize="16px">{value}</Text>
                  <Text ml={4} fontSize={"16px"}>{deliverableTypeToTask[key] ?? ""}</Text>
                </Flex>
              ))}
            </VStack>
          </VStack>
        </Box>
      </Box>
      <Box>
        <Text fontWeight="bold" fontSize={"20px"}>Timeline</Text>
        <Flex justify="space-between">
          <Text fontSize={"18px"}>Come into {campaign.clientName} within <Text as="span" color="red.500">7 Days</Text></Text>
          
        </Flex>
      </Box>
    </VStack>
  );
};

export default CreativeBrief;