import React from 'react';
import { Box, Text, Image, Flex, Button, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import CompletedCollabModal from './CompletedCollabModal';
import CanceledCollabModal from './CanceledCollabModal';
import ExpiredOfferModal from './ExpiredOfferModal';
import { Collab, Campaign } from '../../../types';
import defaultImage from '../../../assets/foodfluence_default_image.jpg';

interface PastCollabComponentProps {
  collab: Collab;
  campaign: Campaign;
  influencerName: string;
}

const PastCollabComponent: React.FC<PastCollabComponentProps> = ({ collab, campaign, influencerName }) => {
  const navigate = useNavigate();
  const completedModalDisclosure = useDisclosure();
  const canceledModalDisclosure = useDisclosure();
  const expiredModalDisclosure = useDisclosure();

  const handleCollabClick = () => {
    switch (collab.status) {
      case 'collabCompleted':
        completedModalDisclosure.onOpen();
        break;
      case 'collabCanceled':
        canceledModalDisclosure.onOpen();
        break;
      case 'collabExpired':
        expiredModalDisclosure.onOpen();
        break;
      default:
        navigate('/collab');
    }
  }
  
  const actionNames = {
    collabCompleted: "Completed",
    collabCanceled: "Rejected",
    collabExpired: "Expired",
  }

  const formatDate = (date: any) => {
    if (date && date.toDate instanceof Function) {
      return date.toDate().toLocaleDateString();
    }
    return 'Invalid Date';
  };

  return (
    <>
      <Box 
        borderWidth={1} 
        borderRadius="lg" 
        overflow="hidden" 
        boxShadow="md"  
        bg="white" 
        width="150px"
        height="200px" 
        display="flex"
        flexDirection="column"
        m={2} 
        cursor="pointer" 
        _hover={{ boxShadow: "lg" }}
        position="relative"
        onClick={handleCollabClick}
      >
        <Image src={campaign?.collabImage ?? defaultImage} height="50%" width="100%" objectFit="cover"/>
        <Box p={2} flex={1} display="flex" flexDirection="column">
          <Flex direction="row" justifyContent="space-between">
          <Text color="black" mb={3} fontSize="sm" fontWeight="bold">{campaign?.clientName}</Text>
          </Flex>
          <Text color={collab.status === 'collabCompleted' ? "green.500" : "gray.500"}>{actionNames[collab.status]}</Text>
        </Box>
        

      </Box>
      
      <CompletedCollabModal
        isOpen={completedModalDisclosure.isOpen}
        onClose={completedModalDisclosure.onClose}
        collabId={collab.id}
        userConfirmRedemption={collab.userConfirmRedemption}
      />
      <CanceledCollabModal
        isOpen={canceledModalDisclosure.isOpen}
        onClose={canceledModalDisclosure.onClose}
        campaignName={campaign?.clientName ?? ""}
      />
      <ExpiredOfferModal
        isOpen={expiredModalDisclosure.isOpen}
        onClose={expiredModalDisclosure.onClose}
        campaignName={campaign?.clientName ?? ""}
      />
    </>
  );
}

export default PastCollabComponent;